// ========== footer ========== //
.footer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 4rem 0 1.5rem 0;
  margin: 0 auto;
  width: 100%;
  background-color: $body-background-color;
  transition: background-color 0.5s ease-out;
  .social {
    width: 50%;
    min-width: 42.8125rem;
    line-height: 2.5rem;
    margin: 0 auto;
    text-align: center;
    vertical-align: text-bottom;
    .iconfont-archer {
      position: relative;
    }
    @include social($scaleSize: 0.8, $spanMargin: 0.5rem);
  }
}

// ========== copyright ========== //≈
.copyright {
  font-family: $feature-font-family;
  margin: 0.5rem auto;
  text-align: center;
  color: $social-iconfont-color;
  a {
    color: $feature-color;
  }
}

.power {
  position: relative;
  vertical-align: baseline;
  font-size: 1rem;
  padding: 0 0.2rem;
}

// ========== website approve ========== //≈
.website-approve {
  font-size: 14px;
  font-family: $feature-font-family;
  margin: 0rem auto 0.5rem;
  text-align: center;
  color: $social-iconfont-color;
  a {
    color: $social-iconfont-color;
  }
}

.icp-approve {
  font-family: $feature-font-family;
}

.beian-img {
  position: relative;
  vertical-align: middle;
  padding: 0 0.2rem 0.3rem 0.2rem;
}

.beian-approve {
  font-family: $feature-font-family;
}

// ========== busuanzi ========== //≈
.busuanzi-container {
  text-align: center;
}

#busuanzi_container_site_pv,
#busuanzi_container_site_uv {
  display: block;
  text-align: center;
  font: 0.6rem $feature-font-family;
  color: #ddd;
}
