.archer- {
  &float-left {
    float: left;
    margin-right: 1rem;
  }
  &float-right {
    float: right;
    margin-left: 1rem;
  }
}
