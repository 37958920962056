// ========== sidebar tags ========== //
.sidebar-panel-tags,
.sidebar-panel-categories {
  padding: 1rem 1rem 5px 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
}

.sidebar-tags-name,
.sidebar-categories-name {
  padding: 0 0 1rem 0;
  max-height: 30%;
  overflow-y: auto;
}

.sidebar-tag-name,
.sidebar-category-name {
  display: inline-block;
  border: 1px solid #fff;
  border-radius: 5rem;
  padding: 0.07rem 0.4rem 0.1rem 0.4rem;
  margin: 0.2rem 0;
  white-space: nowrap;
  font-size: 1rem;
  cursor: pointer;
  color: #fff;
  &:hover {
    border-color: $feature-color;
    color: $feature-color;
  }
  .iconfont-archer {
    pointer-events: none;
    transform: translateY(-1rem);
    font-size: 0.6rem;
    margin: 0 0.3rem 0 0.1rem;
  }
}

.sidebar-category-name {
  .iconfont-archer {
    font-size: 16px;
  }
}

.sidebar-tags-empty,
.sidebar-categories-empty {
  display: none;
}

.sidebar-tags-empty-active,
.sidebar-categories-empty-active {
  display: block;
  font-size: 6rem !important; // TODO: why does it need !important
  margin-top: 7rem;
  user-select: none;
  text-align: center;
  color: #fff;
}

.sidebar-label-focus {
  border-color: $feature-color;
  color: $feature-color;
}

.sidebar-tags-list,
.sidebar-categories-list {
  padding: 1rem 1rem 0 0;
  overflow-y: auto;
}

.meta-post-item {
  @extend .archive-post-item;
}

.meta-post-title {
  @extend .archive-post-title;
}

.meta-post-date {
  @extend .archive-post-date;
}
