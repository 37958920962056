// ========== main ========== //
.post-body,
.about-body {
  main {
    margin: 0;
  }
}

.anchorjs-archer {
  color: $feature-color;
  border: none !important;
}

// ========== intro ========== //
.post-body,
.about-body {
  .site-intro-meta {
    width: 50%;
    min-width: 42.8125rem;
    margin: 0 auto;
    align-items: flex-start;
  }
  .intro-title {
    font: 4rem/5.3rem $feature-font-family;
    color: #fff;
    margin: 0;
  }
}

.post-intros {
  opacity: 0;
  transition: $transition-animation 0.5s;
  transform: translate3d(0, -1rem, 0);
}

.post-intro-tags {
  font: 1.2rem $feature-font-family;
  color: #fff;

  a {
    color: #fff;
  }

  .post-category {
    cursor: pointer;
    position: relative;
    margin-left: 0;
    margin-right: 0.5rem;
    @include hover-line($color: #fff);
  }

  .post-category-icon {
    margin-right: 2px;
    pointer-events: none;
  }

  .post-category-text {
    pointer-events: none;
  }

  .post-tag {
    position: relative;
    margin-left: 0;
    margin-right: 0.5rem;
    @include hover-line($color: #fff);
    &::before {
      margin-right: 2px;
      content: '#';
    }
  }
}

.post-intro-meta {
  margin-top: 0.2rem;
  font: 1.1rem $feature-font-family;
  color: rgba(255, 255, 255, 1);
  display: flex;
  align-items: center;
}

.post-intro-read {
  margin-top: 0.2rem;
  font: 0.9rem $feature-font-family;
  color: rgba(255, 255, 255, 1);
  display: flex;
  align-items: center;
}

.word-count {
  margin-right: 1rem;
}

.post-fade-in {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.post-intro-calander {
  font-size: 0.9rem !important;
  margin-right: 0.3rem;
}

.post-intro-time {
  font-size: 0.9rem;
  margin-right: 0.5rem;
}

.busuanzi-pv,
.busuanzi-uv {
  font-size: 0.9rem;
  margin-right: 0.4rem;
}

.post-intro-busuanzi {
  font-size: 0.9rem !important;
}

.share-wrapper {
  position: relative;
  font-size: 0.9rem;
  padding: 0 0.5rem 0 0;
}

.share-wrapper:hover {
  .share-list {
    visibility: visible;
    opacity: 1;
  }
}

.share-icon {
  font-size: 0.9rem !important;
  cursor: pointer;
}

.share-text {
  font-family: $feature-font-family;
  cursor: pointer;
}

.share-list {
  position: absolute;
  visibility: hidden;
  margin: 0;
  padding: 0;
  left: 100%;
  bottom: 0;
  display: flex;
  opacity: 0;
  transition: all 0.1s ease-in 0s;
}

.share-list .iconfont-archer {
  display: inline-block;
  margin-right: 3px;
  cursor: pointer;
  &:hover {
    color: #aaa;
  }
}

.share-list [data-type='weibo'] {
  position: relative;
  top: -2px;
}

.share-list [data-type='facebook'] {
  position: relative;
  top: 1px;
}

.share-qr {
  position: relative;
}

.share-qrcode {
  position: absolute;
  left: 0;
  bottom: 1.5rem;
  visibility: hidden;
  opacity: 0;
  transition: all 0.1s ease-in 0s;
}

.share-qr:hover {
  .share-qrcode {
    visibility: visible;
    opacity: 1;
  }
}

// ========== paginator ========== //
.post-paginator {
  overflow: hidden;
  margin: 0;
  list-style: none;
  padding: 2.5rem 0 1rem 0;
  display: flex;
  justify-content: space-between;
  li {
    position: relative;
    max-width: 13rem;
  }

  .nextTitle,
  .prevTitle {
    font-size: 1.2rem;
    color: #ccc;
    &:hover {
      color: $feature-color;
    }
  }

  .nextSlogan,
  .prevSlogan {
    font: bold 1.4rem $feature-font-family;
    color: #252525;
  }

  .nextSlogan {
    &::before {
      content: '< ';
      font-size: 1.7rem;
    }
  }

  .prevSlogan {
    &::after {
      content: ' >';
      font-size: 1.7rem;
    }
  }

  .prevTitle,
  .prevSlogan {
    text-align: right;
  }
}

// ========== content ========== //
.article-entry {
  background-color: $foreground-color;
  border-radius: 0.5rem 0.5rem 0 0;
  padding: 0.5rem 1.5rem;
  transition: background-color 0.5s ease-out;
  overflow: hidden;
}

.abstract-content,
.article-entry {
  position: relative;
  font-weight: lighter;
  font-weight: 400;
  color: $post-content-color;
  li {
    word-wrap: break-all;
    line-height: 1.625;
  }
  ul,
  ul li {
    list-style-type: initial;
    margin-top: 0.25em;
  }
  ol,
  ol li {
    list-style-type: decimal-leading-zero;
    margin-top: 0.25em;
  }
  @for $i from 1 through 6 {
    h#{$i} {
      .headerlink {
        display: none;
      }
    }
  }
  // @for $i from 2 through 4 {
  //   h#{$i} {
  //     // &::before {
  //     //     content: "#";
  //     //     margin-right: 2px;
  //     //     color: $feature-color;
  //     //     font: 1em $base-font-family;
  //     //     font-weight: bold;
  //     // }
  //   }
  // }
  // h1 {
  // }
  // h2 {
  // }
  // h3 {
  // }
  // h4 {
  // }
  h5 {
    font-size: 1rem;
    margin: 1rem 0;
  }
  h6 {
    font-size: 0.8rem;
    margin: 1rem 0;
  }
  p {
    line-height: 1.625;
  }
  em {
    font-style: italic;
  }
  img {
    display: block;
    max-width: 100%;
    margin: 1rem auto 1rem;
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.2);
  }
  blockquote {
    margin: 1.5em 0;
    padding: 1rem 1.5rem;
    position: relative;
    background-color: #f6f6f6;
    border-left: 0.35rem solid $feature-color;
    border-radius: 0.35rem;
    transition: $transition-animation;
    p {
      margin: 0;
    }
  }
  .table-container table,
  > table {
    width: 100%;
    border: 1px solid #ddd;
    margin: 1rem 0;
    border-collapse: collapse;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.125);
    tr,
    td {
      height: 2rem;
    }
    thead {
      tr {
        background: #f8f8f8;
      }
    }
    tbody {
      tr:hover {
        background: #efefef;
      }
    }
    td,
    th {
      border: 1px solid #dedede;
      padding: 0.2rem 1rem 0 1rem;
    }
  }
  a {
    color: $feature-color;
    padding: 0 2px 0 2px;
    word-break: break-all;
    &:hover {
      border-bottom: 1px solid $feature-color;
    }
    &.fancybox {
      border: 0;
    }
  }
}

// ========== license ========== //
.license-wrapper {
  background-color: $foreground-color;
  border-radius: 0 0 0.5rem 0.5rem;
  padding: 0.5rem 1.5rem;
  font-weight: 300;
  line-height: 1.5rem;
  border-top: 1px dashed #ccc;
  border-bottom: 1px dashed #ccc;
  color: $post-content-color;
  transition: $transition-animation;
  p {
    margin: 0.2rem 0;
  }
  a {
    color: $feature-color;
    word-break: break-all;
  }
  :first-child {
    margin-top: 1rem;
  }
  :last-child {
    margin-bottom: 1rem;
  }
}

// ========== toc ========== //
.toc-wrapper {
  display: block;
  position: absolute;
  width: 22%;
  right: 1%;
  opacity: 1;
  max-width: calc((100% - 42.8125rem - 4rem) / 2);
  z-index: 9998;
  margin-top: calc(#{$header-height} + 1rem);
  transform: translate3d(0, 0, 0);
  transition:
    transform 0.4s ease-out,
    opacity 0.4s ease-out;
  .toc-active {
    color: $feature-color;
  }
  &-loding {
    opacity: 0;
  }
}

.toc-catalog {
  color: #bbb;
  padding: 0.5rem 0rem;
  z-index: 2;
  cursor: pointer;
  user-select: none;
  font: bolder 1.2rem $feature-font-family;
  &:hover {
    color: $feature-color;
  }
}

.catalog-icon {
  position: relative;
  top: -0.1rem;
  margin-right: 0.3rem;
}

.toc {
  max-height: calc(100vh - #{$header-height} - 5rem);
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}

.toc,
.toc-child {
  padding-left: 0;
  margin: 0 0 0 0;
  padding-top: 2px;
  z-index: 1;
}

.toc-wrapper {
  & > * {
    transform: translate(0, 0);
    transition:
      transform 0.3s ease-out,
      opacity 0.3s ease-out,
      color 0.2s ease-out;
    opacity: 1;
  }
  ol {
    padding-left: 1rem;
  }
  .toc-number {
    font-weight: bold;
  }
  a {
    color: #ccc;
  }
  a:hover {
    color: $feature-color !important;
  }
}

.toc-hide-children {
  & > * {
    transform: translate(20%, 0);
    opacity: 0;
  }
  .toc-catalog {
    display: block;
    transform: none;
    opacity: 1;
  }
}

.toc-fixed {
  position: fixed;
  top: 0rem !important;
}

.toc-slide {
  transform: translate3d($sidebar-width, 0, 0);
}

.toc-hide {
  display: none;
}

.toc-item {
  margin-bottom: 2px;
}

// ========== comment ========== //
.post-comment {
  margin: 1rem 0;
}
