// ========== paginator ========== //
.page-nav {
  padding: 1rem 0 0.5rem 0;
  display: flex;
  justify-content: center;
  & > * {
    font: 1.4rem $feature-font-family;
    color: #252525;
    vertical-align: middle;
    margin-right: 0.5rem;
    text-align: center;
  }
  a {
    position: relative;
    color: #ccc;
    overflow: visible;
    @include hover-line($color: #ccc);
  }
}
