// ========== sidebar archive ========== //
.sidebar-panel-archives {
  padding: 1rem;
  ul {
    padding: 0;
    margin: 0;
  }
}

.ps__rail-x,
.ps__rail-y {
  display: none !important;
}

.post-archive {
  overflow-y: auto;
}

.archive-post-item {
  font-family: $base-font-family;
  display: flex;
  align-items: baseline;
  border-left: 1px solid #bbb;
  margin-left: 0.3rem;
  padding: 0.2rem 0 0.3rem 0.5rem;
  &:hover {
    border-left-color: $feature-color;
    .archive-post-date,
    .archive-post-title {
      color: $feature-color;
    }
  }
}

.total-and-search {
  display: flex;
}

.site-search {
  &.site-search-loading {
    display: none;
  }

  .search-icon {
    cursor: pointer;
    color: $feature-color;
    line-height: 2.3rem;
    font-size: 1.4rem;
    position: relative;
    margin-left: 0.5rem;
  }
}

.archive-year,
.total-archive {
  font: 1.2rem $feature-font-family;
  color: $feature-color;
}

.archive-year {
  font-size: 1rem;
  margin: 0.3rem 0 0.3rem 0;
}

.archive-post-date {
  // align-self: center;
  font: 0.9rem/1rem $feature-font-family;
  color: #999;
}

.archive-post-title {
  font-size: 1rem;
  line-height: 1.4rem;
  margin-left: 0.8rem;
  text-align: left;
  color: #ccc;
}
