.popup {
  display: none;
  position: fixed;
  top: 10%;
  left: 50%;
  width: 700px;
  height: 80%;
  margin-left: -350px;
  padding: 3px 0 0 10px;
  color: #333;
  z-index: 9999;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(8px);
}

@media (max-width: 767px) {
  .popup {
    padding: 3px;
    top: 0;
    left: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    border-radius: 0px;
  }
}

.popoverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 2080;
  background-color: rgba(0, 0, 0, 0.3);
}

.popup-btn-close {
  display: inline-block;
  color: $feature-color;
  cursor: pointer;
  .iconfont-archer {
    font-size: 20px;
  }
}

.algolia-popup {
  overflow: hidden;
  padding: 0;
}

.algolia-popup .popup-btn-close {
  padding: 10px;
  margin-left: 10px;
  border-left: 1px solid #ddd;
}

.algolia-popup .popup-btn-close .fa {
  color: #999;
  font-size: 18px;
}

.algolia-popup .popup-btn-close:hover .fa {
  color: #222;
}

.algolia-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0 5px 10px;
  border-bottom: 1px solid #ccc;
  background: #f5f5f5;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.algolia-search-input-icon {
  display: inline-block;
  width: 20px;
  padding: 10px;
}

.algolia-search-input-icon .fa {
  font-size: 18px;
}

.algolia-search-input {
  display: inline-block;
  width: calc(90% - 20px);
}

.algolia-search-input input {
  padding: 5px 0;
  width: 100%;
  outline: none;
  border: none;
  background: transparent;

  &::-webkit-search-cancel-button {
    display: none;
  }
}

.algolia-powered {
  float: right;
}

.algolia-powered img {
  display: inline-block;
  height: 18px;
  vertical-align: middle;
}

.algolia-stats {
  margin-top: 20px;
  border: {
    bottom: 1px solid;
    color: rgba(245, 245, 250);
  }
}

.algolia-results {
  position: relative;
  overflow: auto;
  height: calc(100% - 50px);

  .algolia-hit-empty {
    &-inner-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &-label {
    }
  }
}

.algolia-results hr {
  margin: 10px 0;
}

.algolia-results .highlight {
  font-style: normal;
  margin: 0;
  padding: 0 2px;
  font-size: inherit;
  color: #f00;
}

.algolia-stat-root {
  font-weight: lighter;
  color: #aaa;
  padding: {
    left: $algolia-search-padding-horiz;
    right: $algolia-search-padding-horiz;
    bottom: calc($algolia-search-padding-horiz / 2);
  }
}

.algolia-hits {
  margin-top: 20px;
}

.algolia-hit {
  &-list {
    padding-left: 0;
  }

  &-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 4em;
    &-label {
      margin-top: 1em;
    }
  }

  &-item {
    margin: 0;
    --border-opacity: 1;
    padding: calc($algolia-search-padding-horiz * 0.75)
      $algolia-search-padding-horiz;
    border: {
      bottom: 1px dashed;
      color: rgba(245, 245, 250, var(--border-opacity));
    }
    cursor: pointer;

    &:hover {
      background: #eee;
      border-color: transparent;
      transition: background 0.15s ease-in-out;
    }

    &-link {
      color: #555;
      padding: 0 0 2px 0;
      display: block;
      transition-duration: 0.2s;
      transition-timing-function: ease-in-out;
      transition-delay: 0s;
      em {
        font-style: normal;
        color: $feature-color;
      }
    }
  }
}

// ===== pagination ===== //
.ais-Pagination-list {
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 20px 0;
  border-top: none;
}

.ais-Pagination-item {
  display: inline-block;
  margin: 0 5px;

  a {
    color: $feature-color;
  }

  &--selected {
    a {
      pointer-events: none;
      cursor: auto;
      color: #222;
    }
  }

  &--page {
    border-top: none;
    &:hover {
      border-bottom: 1px solid #222;
    }
  }
}

.algolia-pop-overlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 50;
  backdrop-filter: blur(2px);
}
