// ========== general style ========== //
html {
  font-size: $base-font-size;
  font-family: $base-font-family;
  overflow-x: hidden;
}

* {
  font-family: inherit;
}

body {
  z-index: 100;
  position: relative;
  background-color: $body-background-color;
  transition: $transition-animation;
}

.wrapper {
  z-index: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  transition: transform 0.3s ease-in;
  transform: translate3d(0, 0, 0);
  position: relative;
  box-shadow: -3px 0 2px 0 rgba(0, 0, 0, 0.6);
}

.wrapper-sidebar-active {
  transform: translate3d($sidebar-width, 0, 0);
}

.main {
  order: 1;
  width: 50%;
  min-width: 42.8125rem;
  flex-grow: 0;
  padding: 0 0 0 0;
  margin-left: 3rem;
}

.container {
  display: flex;
  padding: $container-margin-top 0 0 0;
  position: relative;
  background-color: $body-background-color;
  top: 0;
  left: 0;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  transition: $transition-animation;
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.container-unloaded {
  opacity: 0;
  transform: translate3d(0, 1rem, 0);
}

a {
  text-decoration: none;
}

ul,
li {
  list-style-type: none;
}

// selection
::selection {
  background: $selection-background-color;
  color: $selection-text-color;
}

.fancy-link {
  padding: 0 !important;
  margin: 0 !important;
}

// notes
.note {
  margin: 1.5rem 0;
  padding: 1rem 1.5rem;
  border-left: 0.35rem solid;
  border-radius: 0.35rem;
  transition: $transition-animation;

  .title {
    font-weight: bold;
    margin-top: 1rem;
  }

  &.note-warning {
    background-color: #fdf8ea;
    border-color: #f0ad4e;
  }
}
