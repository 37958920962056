.four-zero-four-body {
  .loading {
    display: none !important;
  }

  .site-intro {
    height: 100%;
  }

  .site-intro-meta {
    height: 80%;
  }

  .intro-title {
    width: 80%;
    text-align: center;
  }

  .intro-subtitle {
    width: 90%;
    text-align: center;
  }

  .wrapper {
    height: 100vh;
    padding-bottom: 0;
  }

  .container {
    padding: 0;
  }

  .footer {
    width: 100%;
    z-index: 30;
    background-color: transparent;
    position: absolute;
    bottom: 0;
    // left: 0;
  }

  .iconfont-archer::before {
    color: rgba(255, 255, 255, 0.5);
  }

  .copyright {
    color: #ddd;
  }

  .website-approve a {
    color: #ddd;
  }

  #busuanzi_container_site_pv,
  #busuanzi_container_site_uv {
    color: #ddd;
  }
}
