// ========== fixed footer ========== //
.footer-fixed {
  position: fixed;
  bottom: 0;
  padding: 1rem;
  overflow: hidden;
  z-index: 10;
  transform: translate3d(0, 0, 0);
  transition: transform 0.3s ease-in 0s;
  // Avoid blocking the click events of lower z-index elements
  // In this case, you should set every other fixed footer element
  // that clickable `pointer-events: auto;`
  pointer-events: none;
}

.footer-fixed-sidebar-active {
  transform: translate3d($sidebar-width, 0, 0);
}

// ========== fixed buttons ========== //
.footer-fixed-btn {
  pointer-events: auto;
  display: block;
  transition: all 0.3s ease;
  font-family: 'iconfont-archer';
  z-index: 21;
  width: 1.5rem;
  height: 1.5rem;
  text-align: center;
  line-height: 1.5rem;
  border: 2px solid $body-background-color-dark;
  background-color: $body-background-color;
  cursor: pointer;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }

  &--rounded {
    color: #333333;
    background-color: $body-background-color;
    width: 2.7rem;
    height: 2.7rem;
    line-height: 2.7rem;
    border: unset;
    border-radius: 50%;

    &:hover {
      color: $body-background-color;
    }
  }

  &--hidden {
    pointer-events: none;
    opacity: 0;
  }

  &:hover,
  &--active {
    background-color: $body-background-color-dark;
    color: $body-background-color;
  }
}
