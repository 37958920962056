// ========== header ========== //
header {
  &.header {
    position: fixed;
    top: 0;
    width: 100%;
    height: $header-height;
    overflow: hidden;
    z-index: 10;
    transform: translate3d(0, 0, 0);
    transition: transform 0.3s ease-in 0s;
    // Avoid blocking the click events of lower z-index elements
    // In this case, you should set every other clickable header element
    // with css attribute `pointer-events: auto;`
    pointer-events: none;
  }

  &.header-sidebar-active {
    transform: translate3d($sidebar-width, 0, 0);
  }

  .header-element {
    pointer-events: auto;
  }
}

// ========== post read progress ========== //
.read-progress {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: $header-border-top-width;
  background-color: rgba(0, 0, 0, 0.7);
  transition:
    transform 0.1s ease-out,
    opacity 0.5s ease-in-out;
  transform: translate3d(-100%, 0, 0);
  opacity: 0;
  z-index: 99999;
}

.read-progress-feature {
  background-color: $feature-color;
}

// ========== header sidebar menu ========== //
.header-sidebar-menu {
  position: absolute;
  left: 1rem;
  top: 50%;
  border: 2px solid $body-background-color;
  width: 1.5rem;
  height: 1.5rem;
  transform: translate(0, -50%);
  transition: all 0.3s ease;
  font-family: 'iconfont-archer';
  color: $body-background-color;
  text-align: center;
  line-height: 1.5rem;
  z-index: 21;
  cursor: pointer;

  &.header-sidebar-menu-active {
    background-color: $body-background-color;
    color: $body-background-color-dark;
  }
}

.header-sidebar-menu-active {
  transform: translate(0, -50%) rotate(180deg) !important;
}

.header-sidebar-menu-rounded {
  font-family: unset;
  transform: translate(0, -50%);
  width: 2.7rem;
  height: 2.7rem;
  line-height: 2.7rem;
  border: unset;
  border-radius: 50%;

  &.header-sidebar-menu-active {
    background-color: transparent;
    color: $feature-color;
  }
}

.header-sidebar-menu-black {
  color: $body-background-color-dark;
  background-color: $body-background-color;
  border-color: $body-background-color-dark;

  &.header-sidebar-menu-active {
    background-color: $body-background-color-dark;
    color: $body-background-color;
  }
}

@media not all and (hover: none) {
  .header-sidebar-menu:hover {
    background-color: $body-background-color;
    color: $body-background-color-dark;
  }

  .header-sidebar-menu-rounded:hover {
    background-color: transparent;
    color: $feature-color;
  }

  .header-sidebar-menu-black:hover {
    background-color: $body-background-color-dark;
    color: $body-background-color;
  }
}

// ========== header actions ========== //
.header-actions {
  position: absolute;
  right: 1rem;
  line-height: 2.7rem;
  top: 50%;
  transform: translate(0, -50%);

  &.header-actions-hide {
    display: none;
  }

  > span {
    margin-left: 1rem;
    padding: 0.5rem;
  }

  // ========== theme mode switch button ========== //
  .header-theme-btn {
    color: #fff;
    cursor: pointer;
    user-select: none;
    transition: all 0.3s ease;

    &-disabled {
      pointer-events: none;
    }
  }

  // ========== home page link ========== //
  .home-link {
    font-family: $feature-font-family;

    a {
      color: #fff;
      transition: all 0.3s ease;
    }
  }

  @media not all and (hover: none) {
    .header-theme-btn:hover {
      color: $feature-color;
    }

    .home-link {
      a:hover {
        color: $feature-color;
      }
    }
  }
}

// ========== header toggle (only take effect in post page) ========== //
header {
  .banner {
    position: relative;
    width: 100%;
    margin: 0 auto;
    height: calc(100% - 1px);
    border-bottom: 1px solid rgba(0, 0, 0, 0.8);
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(4px);
    transform: translate3d(0, -100%, 0);
    transition: transform 0.3s ease 0s;

    .post-title {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 70%;
      margin-left: 15%;

      a {
        white-space: nowrap;
        color: $header-banner-text-color;
        max-width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: center;
        font-size: 1.5rem;
      }

      &.post-title-hide {
        display: none;
      }
    }

    .blog-title {
      display: flex;
      position: absolute;
      right: 1.5rem;
      line-height: 2.7rem;
      top: 50%;
      transform: translate(0, -50%);
      justify-content: center;
      align-items: center;
      height: 100%;
      a {
        vertical-align: top;
        white-space: nowrap;
        color: $header-banner-text-color;
        font: 1rem/1rem $feature-font-family;
        &:hover {
          color: $feature-color;
        }
      }
    }

    &.banner-show {
      transform: translate3d(0, 0, 0);
    }

    &.banner-clean {
      border-bottom: unset;
    }
  }
}
