// ========== main ========== //
.index-post {
  background-color: $foreground-color;
  border-radius: 0.5rem;
  position: relative;
  padding: 1rem;
  z-index: 3;
  transition: background-color 0.5s ease-out;
}

// ========== abstract content ========== //
.index-post {
  .abstract-content {
    color: $abstract-content-text-color;
    font-size: 1rem;
    font-weight: 300;
    word-break: break-word;
    img {
      max-height: 60vh;
      box-shadow: none;
    }
    @for $i from 1 through 6 {
      h#{$i} {
        font-weight: normal;
        .headerlink,
        .anchorjs-link {
          display: none;
        }
      }
    }
  }
}

// ========== abstract title ========== //
.abstract-title {
  display: flex;
  align-items: baseline;
  line-height: 1.625;
  padding-top: 0.5rem;
  transition: color 0.1s ease-in;
  color: $abstract-content-title-color;
  &:hover {
    color: $feature-color;
  }

  .stick-top {
    font-size: 1.3rem;
    margin-right: 0.5rem;
  }

  .abstract-title-text {
    font-size: 1.7rem;
  }
}

// ========== abstract read more button ========== //
.index-post {
  .abstract-read-more-button {
    font-family: $feature-font-family;
    font-size: 1rem;

    a {
      color: $abstract-post-meta-color;
      padding-top: 1rem;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      transition: color 0.1s ease-in;
      &:hover {
        color: $feature-color;
      }
    }
  }
}

// ========== abstract meta ========== //
.abstract-post-meta {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  font-size: 1rem;
  font-family: $feature-font-family;
  color: $abstract-post-meta-color;

  a {
    color: $abstract-post-meta-color;
  }

  .abstract-calander {
    position: relative;
    top: -0.1rem;
    margin-right: 0.3rem;
  }

  .abstract-tags {
    text-align: right;
  }

  .post-category {
    cursor: pointer;
    position: relative;
    margin-left: 0.4rem;
    font-weight: lighter;
    @include hover-line($color: $abstract-post-meta-color);
  }

  .post-category-icon {
    margin-right: 2px;
    pointer-events: none;
  }

  .post-category-text {
    pointer-events: none;
  }

  .post-tag {
    position: relative;
    margin-left: 0.4rem;
    font-weight: lighter;
    &::before {
      margin-right: 2px;
      content: '#';
    }
    @include hover-line($color: $abstract-post-meta-color);
  }
}

// ========== divider ========== //
.index-post-divider {
  margin: 0.5rem;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
}
